import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../widgets/Layout";
import { graphql } from "gatsby";
import { Card } from "react-bootstrap";

// markup
const IndexPage = ({ data }) => {
  // console.log("data",data?.page?.nodes[0]?.childMdx?.body)
  return (
    <Layout>
      <section className="container pt-2 mb-5">
        {/* <MDXRenderer>{data?.page?.nodes[0]?.childMdx?.body}</MDXRenderer> */}
        <Card className="bg-white custom-card">
          <Card.Body className="markdown-content">
            <MDXRenderer>{data?.page?.nodes[0]?.childMdx?.body}</MDXRenderer>
          </Card.Body>
        </Card>
      </section>
    </Layout>
  );
};

export default IndexPage;
export const query = graphql`
  query getPage1 {
    page: allFile(filter: { relativeDirectory: { eq: "landing" } }) {
      nodes {
        childMdx {
          frontmatter {
            title
          }
          slug
          body
        }
      }
    }
  }
`;
